import React, { useState } from "react";
import Add from "@material-ui/icons/Add";
import { Box, Grid, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import cx from "classnames";

import { SkipNext } from "@material-ui/icons";
import OptionsGroup from "../../components/OptionsGroup";
import Button from "../../components/Button";
import { assetsOptions } from "../../../../commons/loan-application-constants";
import { useStyles as useStepStyles } from "../../../../utils/loan-application.styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  noMarginTop: {
    marginTop: "0",
  },
}));
const AssetsSelection = ({
  selectedAssets,
  setSelectedAssets,
  formik,
  handleSaveAssetTypes,
}) => {
  const classes = useStyles();
  const stepClasses = useStepStyles();

  const shortListedAssets = assetsOptions.slice(0, 6);
  const [displayedAssets, setDisplayedAssets] = useState(shortListedAssets);

  const toggleAssetList = () => {
    if (displayedAssets.length === shortListedAssets.length) {
      setDisplayedAssets(assetsOptions);
      return;
    }
    setDisplayedAssets(shortListedAssets);
  };

  return (
    <Box classes={{ root: classes.container }}>
      <Grid container xs={12} sm={6}>
        <OptionsGroup
          title="Select Assets You Own"
          options={displayedAssets}
          selectedValue={selectedAssets}
          onSelect={setSelectedAssets}
          multiple
        />
      </Grid>
      <Grid item>
        <Button
          endIcon={
            displayedAssets.length === shortListedAssets.length ? (
              <Add />
            ) : (
              <ExpandLessIcon />
            )
          }
          onClick={toggleAssetList}
        >
          {displayedAssets.length === shortListedAssets.length
            ? `See full list`
            : `Show less`}
        </Button>
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        classes={{
          root: cx(stepClasses.navigationButtonsContainer, classes.noMarginTop),
        }}
      >
        <Button
          endIcon={
            formik.values.assetTypes.length === 0 ? (
              <SkipNext />
            ) : (
              <NavigateNextIcon />
            )
          }
          onClick={handleSaveAssetTypes}
        >
          {formik.values.assetTypes.length === 0 ? "Skip" : "Next"}
        </Button>
      </Grid>
    </Box>
  );
};

export default AssetsSelection;
